<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <!-- nicename -->
      <template #[`item.nicename`]="{item}">
        {{ item.nicename }}
      </template>

      <!-- subject -->
      <template #[`item.subject`]="{item}">
        {{ t(item.subject) }}
      </template>

      <!-- merchant_id -->
      <template #[`item.merchant_id`]="{item}">
        {{ t(item.merchant.name) }}
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <edit-dialog
                v-if="$can('merchant_template:update', 'merchant_template_management')"
                :resource="item"
                @updated="paginate"
              />
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDotsVertical } from '@mdi/js'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import EditDialog from './EditDialog.vue'

dayjs.extend(utc)

export default {
  components: {
    EditDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops,
    } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'NICENAME', value: 'nicename' },
      { text: 'SUBJECT', value: 'subject', sortable: false },
      { text: 'MERCHANT_ID', value: 'merchant_id' },
      { text: 'DATE', value: 'created_at' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      t,
      dayjs,
      formatDate,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
