<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Resource
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <translation-locales />
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="form.type"
              label="Type"
              :items="typeOptions"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
              :disabled="!!resource"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="form.merchant_id"
              :items="merchantOptions"
              item-text="label"
              item-value="value"
              placeholder="Merchant"
              single-line
              outlined
              clearable
              hide-details
              :disabled="disabledFields.includes('merchant_id') || !!resource"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.nicename"
              label="Name"
              outlined
              :rules="[validators.required]"
            />
          </v-col>

          <v-col cols="12">
            <translatable-input
              v-model="form.subject"
              label="Subject"
              outlined
            />
          </v-col>

          <v-col cols="12">
            <translatable-textarea
              v-model="form.content"
              label="Content"
              outlined
            />
            <p>This supports custom formatting:</p>
            <ul>
              Variables:

              All SKUs merchant Template:
              <li>@foreach(skus as sku) [[sku,name,en]] @endforeach</li>
              <li>[[merchant,name,en]]</li>
            </ul>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslatableTextarea, TranslationLocales } from '@/components'
import { useMerchant, useTranslatable } from '@/composables'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: {
    TranslatableInput,
    TranslatableTextarea,
    TranslationLocales,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    resource: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false)

    const useFieldValue = (fieldName, defaultValue) => (props.resource ? props.resource[fieldName] : defaultValue)

    const { merchantOptions, fetchMerchants } = useMerchant()
    fetchMerchants({ take: 999 })

    const typeOptions = [
      { title: 'Low Stock', value: 'low_stock' },
      { title: 'Out of Stock', value: 'out_of_stock' },
    ]

    const defaultFormValue = ({
      type: useFieldValue('type', typeOptions[0].value),
      nicename: useFieldValue('nicename', 'Example Default Name'),
      merchant_id: useFieldValue('merchant_id', null),
      content: useFieldValue('content', useTranslatable()),
      subject: useFieldValue('subject', useTranslatable()),
    })

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const onSubmit = () => {
      if (formRef.value.validate()) {
        console.log(form.value)

        emit('submit', form.value)
      }
    }

    const onClose = () => {
      if (isEditing.value) {
        formRef.value.resetValidation()
      } else {
        formRef.value.reset()

        Object.assign(form.value, defaultFormValue)
      }

      emit('close')
    }

    return {
      isEditing,

      form,
      formRef,

      merchantOptions,

      // Rules
      validators: {
        required,
      },

      typeOptions,

      t,
      onSubmit,
      onClose,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
